<template>
    <span>
        <div class="ol-dashboard__account">
            <div class="ol-dashboard__account-item">
                <h4 class="ol-dashboard__container-title">Cek Point</h4>
                <p class="ol-dashboard__account">Pengecekan point berdasarkan Nomor Handphone.</p>
                <alert-error v-if="message.error != null" :pesan="message.error"/>
                <alert-success v-if="message.ok != null" :pesan="message.ok"></alert-success>
                <div class="add-fix">
                    <div  class="ol-input-box ol-input-box--half">
                        <label class="ol-input-label">Nomor HP</label>
                        <div class="ol-input-ico" >
                            <input type="number" v-model="input.phone" class="ol-input"  />
                        </div>
                    </div>
                    
                    <div class="ol-input-box ol-input-box--full">
                        <button type="button" name="" class="ol-btn" @click="check()">Cek</button>
                    </div>
                </div>

                <span id="point" style="font-size: 24px;font-weight: bold;"></span>

            </div>
        </div>
        <LoadingImg v-if="loading" />
    </span>
</template>

<script>
import Password from 'vue-password-strength-meter'
import globalUrl from '@/util/globallUrl'
import {mapGetters} from "vuex";
import axios from 'axios';
const URL = globalUrl.WS_PROFILE.PASSWORD;

export default {
    name:"Point",
    components:{
        Password,
    },
    computed:{
        ...mapGetters('auth', ['user']),
    },
    data(){
        return {
            input: {
                name: null,
                gender: null,
                birthday: null,
                phone: null,
                account_fb: null,
                account_ig: null,
                account_tiktok: null,
            },
            form:{
                last_password: null,
                new_password: null,
                confirm_password: null,
            },
            loading:false,
            message:{
                ok:null,
                error:null,
            },
        }
    },
    computed: {
        ...mapGetters("auth", ["user", "progressProfile"]),
    },
    methods:{
        showFeedback ({suggestions, warning}) {
            // console.log('🙏', suggestions)
            // console.log('⚠', warning)
        },
        async check(){
            this.message.error=null;
            this.message.ok=null;
            const options = {
                method: 'GET',
                url: 'https://service.morefurniture.id/customer/085333367657',
                headers: {
                Authorization: '$2a$12$FQsB6y5VxAAuqJDXFAMseuv4f0Cp12Lwf6lo0ciBxI2o0PZsvtKGu',
                },
            };

            axios(options)
                .then((response) => {
                // Menampilkan data dari response
                this.customerData = response.data;
                var phone = this.customerData.data.points;
                phone = phone.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                $("#point").html("Jumlah Poin Anda : "+phone);
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    async mounted() {
        this.input.phone = this.user.phone;
    },
}
</script>

<style>

</style>
